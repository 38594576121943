@import "_color-variables";

/* global variable styles */

:root {
    --lightest-grey: #cccccc;
    --light-grey: #999999;
    --base-grey: #666666;
    --dark-grey: #333333;
    --white: #ffffff;
    --green: #28a745;
    --toro-red: #CC0000;
    --red: #bd2130;
    --alert-text: #630303;
    --rm-blue: #267dc1;
    --transparent: rgba(0, 0, 0, 0);

    --primary-color: var(--rm-blue);
    --secondary-color: var(--white);
    --border-color: var(--transparent);
}

/* Use this class to set the properties of the header */
#ic-header {
    background-color: $ic-header-background-color;
    background-image: -webkit-linear-gradient(top, #267dc1, #267dc1);
    top: -3px;
    left: 0px;
}

/* Use this class to set the properties of the footer */
.page-footer {
    background: $ic-header-background-color;
}

.LanguageMenu {
    color: white;
}

/* Use this class to set the backgorung color of the body*/
body {
    /* font-family: "Open Sans",Arial,Helvetica,Sans-Serif; */
    /* font-size: 13px; */
    /* line-height: 1.42857143; */
    /* color: #333; */
    background-color: #fff;
    overflow-x: hidden;
}

/* Use this class to set the width of the items in the horizontal menu bar */
.menu-on-top .menu-item-parent {
    max-width: 100px;
    min-width: 75px;
}

/* Use this class to set the font properties of the small hyperlink */
.linknew {
    visibility: visible;
    font-family: Arial;
    font-weight: normal;
    font-size: 9pt;
    text-decoration: none;
    color: Blue;
    cursor: pointer;
}

/* Use this class to set the cursor hand for the link hover event */
a:link {
    cursor: pointer;
}

/* Use this class to set the background color of the table header */
.TableHeaderClass {
    font-size: 12px;
    font-family: verdana;
    color: #3276b1;
    font-weight: bold;
    cursor: pointer;
}

/* Use this class to set the background color of the table header */
.TableHeader {
    background-color: #267dc1;
}

/* The text area backgroung needs to transoarent */
textarea {
    background-color: transparent;
}

.MessageBoxContainer {
    background-color: #267dc1;
}

.mx-auto {
    margin: 0 auto;
}

.mx-10 {
    margin: 10px auto;
}

.text-bold {
    font-weight: bold;
}

.text-italic {
    font-style: italic;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-space-around {
    display: flex;
    justify-content: space-around;
}

.bootstrap-timepicker {
    width: 150px;
    display: inline-table;
}

i.spin {
    -webkit-animation: pace-spinner 1500ms linear infinite;
    -moz-animation: pace-spinner 1500ms linear infinite;
    -ms-animation: pace-spinner 1500ms linear infinite;
    -o-animation: pace-spinner 1500ms linear infinite;
    animation: pace-spinner 1500ms linear infinite;
}

@-webkit-keyframes app-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-moz-keyframes app-spin {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-o-keyframes app-spin {
    0% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-ms-keyframes app-spin {
    0% {
        -ms-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes app-spin {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.disabled-text {
    color: rgba(0, 0, 0, 0.5)
}

.hover-text {
    color: rgb(50, 118, 177);
}

.hover-text:hover {
    color: rgb(33, 78, 117);
    cursor: pointer;
}

.hidden {
    display: none;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mark-test {
    background-color: red;
}