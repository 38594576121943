.ic-network-performance{
    tr{
        td{
            cursor: pointer;
        }
        &:hover{
            td{
                background-color: var(--lightest-grey);
            }
        }
    }
    tr.selected{
        td {
            color: white;
            background-color: var(--primary-color);
        }
    } 

    .mat-horizontal-content-container{
        padding: 0
    }
    
    .mat-stepper-horizontal, .mat-stepper-vertical{
        background-color: transparent;
    }
    
    .mat-horizontal-stepper-header-container{
        background-color: var(--white);
    }

    .ic-network-performance-step-view-performance{

        .highcharts-scrollbar-thumb,  .highcharts-scrollbar-thumb + path{
            display: none;
          }
        }
    }         
