/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;

@include mat.core();

/* #GOOGLE FONT */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700");

// Configure Material Components Theme

@import "icentral-mat-palettes";

//bryntum calender
//@import "@bryntum/calendar/calendar.material.css";


$iCentral-primary: mat.define-palette($iCentral-Primary, 500);
$iCentral-accent: mat.define-palette($iCentral-mat-lightblue, 600);
// The "warn" palette is optional and defaults to red if not specified.
$iCentral-warn: mat.define-palette(mat.$red-palette);

$iCentral-theme: mat.define-light-theme((color: (primary: $iCentral-primary,
        accent: $iCentral-accent,
        warn: $iCentral-warn,
),
    //typography: mat.define-typography-config(),
        density: 0,
));

// Emit theme-dependent styles for common features used across multiple components.
//@include mat.core-theme($iCentral-theme);

@include mat.all-component-themes($iCentral-theme);

// General Styles

@import "icentral_responsive_mixin";
@import "smart-admin-css-overrides";
@import "icentral-resets";
@import "icentral-nt-style";
@import "a-app-foundation";
@import "b-prime-ng-overrides";
@import "c-material-overrides";
@import "d-app-layout";
@import "e-ic-custom-controls";
@import "f-ic-accounts";
@import "g-network-performance-view";
@import "h-carousel";
