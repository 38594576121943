
ic-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.ic-ah-snap,
.ic-coming-soon {
    height: 100%;
    display: flex;
}
