/* For use in src/lib/core/theming/_palette.scss */
$iCentral-Primary: (
        50 : #e5eff8,
        100 : #bed8ec,
        200 : #93bee0,
        300 : #67a4d4,
        400 : #4791ca,
        500 : #267dc1,
        600 : #2275bb,
        700 : #1c6ab3,
        800 : #1760ab,
        900 : #0d4d9e,
        A100 : #cde1ff,
        A200 : #9ac2ff,
        A400 : #67a3ff,
        A700 : #4d94ff,
        contrast: (50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$iCentral-mat-blue: (
        50 : #E3F2FD,
        100 : #BBDEFB,
        200 : #90CAF9,
        300 : #64B5F6,
        400 : #42A5F5,
        500 : #2196F3,
        600 : #1E88E5,
        700 : #1976D2,
        800 : #1565C0,
        900 : #0D47A1,
        A100 : #82B1FF,
        A200 : #448AFF,
        A400 : #2979FF,
        A700 : #2962FF,
        contrast: (50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #ffffff,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

$iCentral-mat-lightblue: (
        50 : #E1F5FE,
        100 : #B3E5FC,
        200 : #81D4FA,
        300 : #4FC3F7,
        400 : #29B6F6,
        500 : #03A9F4,
        600 : #039BE5,
        700 : #0288D1,
        800 : #0277BD,
        900 : #01579B,
        A100 : #80D8FF,
        A200 : #40C4FF,
        A400 : #00B0FF,
        A700 : #0091EA,
        contrast: (50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #ffffff,
        )
);


//
//

$iCentral-blue: (
        50 : #e2f6ff,
        100 : #b7e8fe,
        200 : #88d8fd,
        300 : #5cc9fb,
        400 : #40bdfa,
        500 : #32b1f9,
        600 : #2ea2e9,
        700 : #298fd5,
        800 : #267ec1,
        900 : #1f5e9f,
        contrast: (50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
        )
);