
.p-overlaypanel {
    &.ic-op-sub-accounts,
    &.ic-op-user-details,
    &.ic-op-remove-controller {
        font-size: 13px;
    }
}

.ic-op-sub-accounts {
    &.p-overlaypanel {
        max-width: 300px;

        #ic-sa-move-note {
            margin-top: 3px;
        }
    }
}

.ic-op-remove-controller {
    &.p-overlaypanel {
        max-width: 300px;
    }
}

