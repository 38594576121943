.carousel-fill-height {
    .carousel.slide, .carousel-inner {
        height: 100%;

        .item {
            height: 100%;

            @include responsive("l-mobile") {
                height: 600px;
            }
        }
    }
}

.onboarding-carousel {
    .carousel-control {
        display: none;
    }

    .carousel-indicators li {
        border-radius: 30px;
        border: 1px solid rgba(var(--bg-light-grey-rgb), 0.20);
        background: rgba(var(--bg-light-grey-rgb), 0.10);
        width: 3.7em;
        height: .5em;
        margin: .3em;

        &.active {
            background: var(--white);
            border-color: var(--white);
        }
    }
}
