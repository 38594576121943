/*----- Flex ----- */
.nt-flex {
    display: flex;
}

.nt-flex-space-bw {
    justify-content: space-between;
}

.nt-flex-vertical-middle {
    align-items: center;
}

.nt-flex-space-bw {
    justify-content: space-between;
}

.nt-flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.nt-flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: column;
}

.nt-align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/*----- Flex End ----- */

/*---- Text-formating ----*/
.nt-light-text {
    color: var(--alto);
}

.nt-error-text {
    color: var(--toro-red);
}

/*----- alignments ----- */
.nt-text-right {
    text-align: right;
}

.nt-text-center {
    text-align: center;
}

.nt-position-relative {
    position: relative;
}

/*----- alignments End----- */


/*----- casing starts ----*/
.nt-uppercase {
    text-transform: uppercase;
}

.nt-bold {
    font-weight: 700;
}

.nt-semi-bold {
    font-weight: 500;
}

/*---- casing ends ----*/

/*---- common structure styling Start----*/

//Eventually we will use this instead of SmartAdmin #content Id. 
#nt-top-wrapper {
    padding-top: 10px;
    padding-bottom: 50px;
}

.nt-well {
    background-color: var(--bg-light-grey);
    border: 1px solid var(--d-grey);
    box-shadow: 0 1px 1px var(--shadow-grey);
    -webkit-box-shadow: 0 1px 1px var(--shadow-grey);
}

.nt-flex-col {
    display: flex;
    flex-flow: column;
}

.nt-flex-same-col-w {
    flex: 1 1 0px; // px is required for internet explorer.
}

//only use with flex | column parent
.nt-flex-fill-height {
    flex: 1;
}

.nt-fill-height {
    height: 100%;
}

.nt-w-100 {
    width: 100%
}

/*---- common structure styling Ends----*/

/*---- Fit-screen-layout Starts----*/
.screen-fit-layout {
    #ic-main-container {
        display: flex;
        flex-flow: column;

        #ic-main-content {
            position: relative;

            & > *:not(router-outlet) {
                height: 100%;
                display: block;
            }
        }
    }

    ic-screen-fit-content {
        height: 100%;
        display: block;
    }

    .screen-fit-flex-spinner {
        & > :nth-child(2) {
            flex: 1;
        }
    }
}

/*---- Fit-screen-layout Ends ----*/

@media (max-width: 600px) {
    #nt-top-wrapper {
        padding-bottom: 0;
    }

}

/*----- spacer -----*/
.nt-spacer-10 {
    margin-bottom: 10px;
    display: block;
}


/*----- list ------*/
.nt-not-list {
    padding-left: 0;
    list-style: none;
}

/*--- auth pages ---*/
.auth-pages {
    h1 {
        font-size: 5rem;
        font-weight: bold;
        margin: 0 0 2.8rem;

        @include responsive("xl-desktop") {
            font-size: 4.2rem;
        }
    }

    h3 {
        font-weight: bold;
        margin: 0 0 8px;
    }

    p {
        font-size: 1.4rem;
        line-height: 21px;
        color: var(--text-light-grey);

        .large-text {
            font-size: 1.26em;
            line-height: 1.5em;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.nt-uppercase {
            letter-spacing: 0.5px;
        }
    }

    a {
        font-weight: 500;
        cursor: pointer;
    }

    .auth-page {
        padding: 4rem 12rem;

        @include responsive("xl-desktop") {
            padding: 10px 6rem;
        }

        @include responsive("l-desktop") {
            padding: 0 5em;
        }

        @include responsive("desktop") {
            padding: 50px 3em;
        }

        @include responsive("mobile") {
            padding: 50px 15px;
        }
    }
}


/*---- Form -----*/
.nt-form {
    .nt-form-field-wrap {
        position: relative;

        .nt-form-field-icon {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;
            display: flex;
            align-items: center;
        }
    }

    .nt-form-flex-gap {
        column-gap: 16px;
    }

    .nt-form-fields-sbs-50 {
        @include responsive("l-desktop") {
            column-gap: 0;
            flex-flow: column;
        }

        @include responsive("desktop") {
            column-gap: 16px;
            flex-flow: row;
        }

        @include responsive("l-mobile") {
            column-gap: 0;
            flex-flow: column;
        }
    }

    .nt-form-header {
        margin-bottom: 3.2rem;
    }

    .form-group {
        margin-bottom: 17px;

        .nt-label {
            color: var(--label-text-color);
            font-size: 1.5rem;
            line-height: 20px;
            margin-bottom: 5px;
        }

        .nt-form-field {
            border: 1px solid var(--natural-border);
            border-radius: 4px;
            padding: 6px 12px;
            display: flex;

            &:focus {
                border-color: rgba(var(--primary-rgb), .7);
            }
        }
    }

    .nt-position-relative {
        .nt-form-error-message {
            position: absolute;
            top: 100%;
            right: 0;
            color: var(--toro-red);
            padding-top: 4px;
        }
    }

    .has-error {
        position: relative;

        .nt-form-field {
            border-color: var(--toro-red);
        }

        .help-block {
            position: absolute;
            top: 0;
            margin-top: 5px;
            right: 0;

            @include responsive("xl-desktop") {
                font-size: 12px;
            }

            @include responsive("l-desktop") {
                font-size: 10px;
            }

            @include responsive("mobile") {
                font-size: 10px;
                top: 9px;
            }

            &[data-bv-result="INVALID"] + .help-block[data-bv-result="INVALID"] {
                top: -17px;
            }
        }
    }

    .nt-form-footer {
        margin: 2.4rem 0 2.4rem;

        .nt-submit-btn {
            padding: 1rem 1.2rem;
            background-color: var(--primary-color);
            color: var(--white);
            letter-spacing: 0.5px;
            text-transform: uppercase;
            border-radius: 4px;
            border: 0;
            display: block;
            font-weight: 500;
            font-size: 1.4rem;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: var(--nt-secondy-color);
            }
        }
    }

    .toggle-password-btn {
        padding: 0;
        background: transparent;
        border: 0;
        color: var(--label-text-color)
    }

}

.nt-form-text-wrap {
    margin: 1.6rem 0;
    padding-top: .4rem;

    .nt-form-text {
        font-weight: 500;

        &.font-light-normal {
            font-weight: normal;
            color: var(--text-light-grey);
        }

        .nt-form-text-link {
            color: var(--primary-color);
            cursor: pointer;

            &:hover {
                color: var(--nt-secondy-color);
            }
        }
    }
}

/*---- Tooltip ---*/
.nt-tooltip {
    position: absolute;
    width: 0;
    height: 0;
    background: rgba(var(--black-rgb), 0.6);
    border-radius: 4px;
    transition: transform .1s ease-in-out, width .1s ease-in-out, height .1s ease-in-out;
    visibility: hidden;
    color: var(--white);
    font-size: .8em;
    padding: 4px 8px;
    transform: scale(1, 0);
    z-index: 2;

    &.show {
        width: fit-content;
        height: fit-content;
        visibility: visible;
        transform: scale(1, 1);
    }
}
