$nt-full-hd: 2559px; // max-width for Screens: 1920px to 2559px
$nt-xl-desktop: 1919px; // max-width for Screens: 1440px to 1919px  (1440w x 900h)
$nt-l-desktop: 1439px; // max-width for Screens: 1200px to 1439px   (1200w x 800h)
$nt-desktop: 1199px; // max-width for Screen: 992px to 1199px       (992w x 558h)
$nt-tablet: 991px; // max-weight for Screen: 768px to 991px         (768w x 1024h)
$nt-l-mobile: 767px; // max-width for Screen: 576px to 767px
$nt-mobile: 575px; // max-width for Screen: 575px and below (375) (for fold : 344)

// map the breakpoints
$nt-breakpoints: (
        "mobile": (
                max-width: $nt-mobile,
        ),
        "l-mobile": (
                max-width: $nt-l-mobile,
        ),
        "tablet": (
                max-width: $nt-tablet,
        ),
        "desktop": (
                max-width: $nt-desktop,
        ),
        "l-desktop": (
                max-width: $nt-l-desktop,
        ),
        "xl-desktop": (
                max-width: $nt-xl-desktop,
        ),
        "full-hd": (
                max-width: $nt-full-hd,
        )
);


@mixin responsive($size) {
    $query: map-get($nt-breakpoints, $size);
    $max-width: map-get($query, max-width);

    @media (max-width: #{$max-width}) {
        @content;
    }
}
