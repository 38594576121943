// Legacy Colors
//$ic-menu-background-color: #3a3633;
//$ic-menu-color: rgba(192,187,183);

$ic-header-background-color: #267dc1;
$ic-footer-background-color: #267dc1;
$ic-mat-dlg-border-color: #e0e0e0;
//$ic-menu-sep-color: #4e4e4e;                  // Legacy Color
$ic-menu-sep-color: #ddd;

/* global variable styles */

:root {
    --alto: #e0e0e0;
    --lightest-grey: #cccccc;
    --light-grey: #999999;
    --base-grey: #666666;
    --dark-grey: #333333;
    --white: #ffffff;
    --toro-red: #CC0000;
    --rm-blue: #267dc1;
    --natural-border: #BCC6CC;
    --transparent: rgba(0, 0, 0, 0);

    --text-color: #2a2a2a;
    --label-text-color: #33393D;
    --text-color-light: #808080;
    --text-light-grey: #646E73;
    --text-white-light: #d9dfe2;
    --text-light-blue: #A2ADB2;

    --bg-light-grey: #fbfbfb;
    --bg-light-grey-rgb: 251, 251, 251;
    --white-smoke: #f8f8f8;
    --border-grey: #e9e9e9;
    --shadow-grey: #ececec;
    --d-grey: #ddd;

    --primary-color: var(--rm-blue);
    --nt-secondy-color: #33393D;
    --secondary-color: var(--white);
    --border-color: var(--transparent);
    --primary-light-bg: #f7f9fa;

    --primary-rgb: 38, 125, 193;
    --white-rgb: 255, 255, 255;
    --black-rgb: 0, 0, 0;
    --dark-rift-rgb: 8, 13, 18;

    --cal-event-red: #ff6c6c;
    --cal-event-pink: #ff6cdd;
    --cal-event-violet: #896cff;
    --cal-event-purple: #c76cff;
    --cal-event-indigo: #304FFE;
    --cal-event-blue: var(--primary-color);
    --cal-event-teal: #00BFA5;
    --cal-event-green: #14aa5a;
    --cal-event-lime: #AEEA00;
    --cal-event-yellow: #FFD600;
    --cal-event-orange: #ff6b56;
    --cal-event-cyan: #00b4d8;
    --cal-event-gray: #aeb1b6;

    // controller setting color
    --cs-et: #fbc376;
    --cs-master-valve: #8dd19e;
    --cs-rain-sensor: #ed5959;
    --cs-pump: #e68858;
    --cs-irrigation: #5cacc5;
    --cs-conventional-run: #93a57d;
    --cs-cycle-sock-run: #9b5939;
}
