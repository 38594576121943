@import "./_color-variables";

$mat-checkbox-color: #0062cc;
$mat-radiobutton-color: #0062cc;

//.mat-mdc-raised-button.mat-primary {
//    --mdc-protected-button-container-color: #3276b1; //#3f51b5;
//    --mdc-protected-button-label-text-color: #fff;
//}
//
//.mat-mdc-dialog-actions {
//    .mat-mdc-button:not(:disabled) {
//        color: var(--mdc-text-button-label-text-color, inherit);
//
//        &:focus {
//            color: white;
//        }
//
//        &:hover {
//            color: white;
//        }
//
//        &:active {
//            color: white;
//        }
//    }
//}
//
//// Checkbox overrides
//.mat-mdc-checkbox.mat-accent {
//    --mdc-checkbox-selected-focus-icon-color: $mat-checkbox-color;
//    --mdc-checkbox-selected-hover-icon-color: $mat-checkbox-color;
//    --mdc-checkbox-selected-icon-color: $mat-checkbox-color;
//    --mdc-checkbox-selected-pressed-icon-color: $mat-checkbox-color;
//}
//
//.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
//    background: $mat-checkbox-color;
//}
//
//// Radio button overrides
//.mat-mdc-radio-button.mat-accent {
//    --mdc-radio-selected-focus-icon-color: $mat-radiobutton-color; // #ff4081;
//    --mdc-radio-selected-hover-icon-color: $mat-radiobutton-color; //#ff4081;
//    --mdc-radio-selected-icon-color: $mat-radiobutton-color; //#ff4081;
//    --mdc-radio-selected-pressed-icon-color: $mat-radiobutton-color; //#ff4081;
//    --mat-mdc-radio-checked-ripple-color: $mat-radiobutton-color; //#ff4081
//
//    &:not(:hover) {
//        --mat-mdc-radio-checked-ripple-color: transparent;
//    }
//}
//
//.mat-mdc-radio-button.mat-warn {
//    --mdc-radio-selected-focus-icon-color: $mat-radiobutton-color; //#f44336;
//    --mdc-radio-selected-hover-icon-color: $mat-radiobutton-color; //#f44336;
//    --mdc-radio-selected-icon-color: $mat-radiobutton-color; //#f44336;
//    --mdc-radio-selected-pressed-icon-color: $mat-radiobutton-color; //#f44336;
//    --mat-mdc-radio-checked-ripple-color: $mat-radiobutton-color; //#f44336
//}

.mdc-button {
    font-size: 14px;
}

.mat-mdc-radio-button {
    margin: 2px 0;

    .mdc-form-field {
        height: 30px;
    }

    .mdc-radio {
        --mdc-radio-state-layer-size: 38px;
        padding: calc((var(--mdc-radio-state-layer-size, 30px) - 20px) / 2) !important;
    }

    // override bootstrap
    label {
        margin-bottom: 0;
    }

}

.mat-mdc-card {
    &.ic-mat-overview-card {
        padding: 5px 16px;
    }
}

//

.ic-cg-actions {
    .mat-mdc-button {
        min-width: 116px;

        &:not(:disabled) {
            color: white;
        }
    }
}

.mat-mdc-dialog-container {
    .mdc-dialog__content {
        &#ic-mat-dialog-content {
            font-family: unset;
            line-height: unset;
            font-size: 14px;
            font-weight: unset;
            letter-spacing: unset;
        }
    }
}

th.align_center .mat-sort-header-container {
    justify-content: center;
}

.mat-mdc-dialog-container {
    .ic-dialog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 53px;
        border-bottom: 1px solid $ic-mat-dlg-border-color;

        &.ic-error-dialog {
            h2 {
                color: var(--alert-text);
            }
        }

        .close-button {
            float: right;
            z-index: 1;

            &:hover {
                background-color: unset;
            }

            &:active {
                background-color: unset;
            }

            .close-icon {
                transition: 0.5s ease-in-out;
            }

            &:hover {

                //background-color: red;
                .close-icon {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .ic-dialog-content {
        color: rgba(0, 0, 0, .87);

        span {
            margin-left: 5px;
        }

        .btn-wrap {
            margin: 15px 0 0;

            button {
                margin-top: 10px;
            }
        }

        .btn-link {
            color: inherit;

            i {
                display: inline-block;

                & + span {
                    display: inline-block;
                    margin-left: 5px;
                }
            }
        }
    }

    .mat-mdc-dialog-actions {
        justify-content: flex-end;
        border-top: 1px solid $ic-mat-dlg-border-color;
    }

}

.isr-controller-list {
    .mat-mdc-row.selected, .mat-mdc-row.selected:hover {
        background-color: var(--primary-color);

        .mdc-data-table__cell {
            color: var(--white)
        }
    }
}

.select-controller-style {
    .mat-checkbox-checked .mat-checkbox-background {
        background-color: #0062cc;
    }

    .mat-checkbox-indeterminate .mat-checkbox-background {
        background-color: #0062cc;
    }

    .popover {
        width: 250px;
        max-width: 300px;
    }

    .mat-row.highlighted {
        background: rgb(236, 243, 248);
    }
}
