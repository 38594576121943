@import "_color-variables";
@import "_sizing-variables";

// Main Menu and Popup Menus
.p-menuitem-text {
    font-size: 13px;
}

.p-menuitem-icon {
    margin-top: 3px;
    font-size: 16px;
    width: 18px;
}

// Main Menu - Desktop
@media screen and (min-width: 961px) {

    .p-menubar {
        //background-color: @ic-menu-background-color;

        .p-menubar-root-list>.p-menuitem>.p-menuitem-link {

            .p-menuitem-icon,
            .p-menuitem-text,
            .p-submenu-icon {
                //color: @ic-menu-color;
            }
        }


        .p-menubar-root-list {
            .p-menuitem {
                min-width: 93px;
                border-right: 1px solid $ic-menu-sep-color;

                .p-menuitem-link {
                    flex-direction: column;
                    height: 62px;
                    padding-top: 4px;
                    padding-bottom: 0;
                    color: var(--text-color);

                    &:hover {
                        color: var(--rm-blue);
                    }

                    .p-menuitem-icon {
                        color: inherit;
                        font-size: 22px;
                        height: 26px;
                    }

                    .p-submenu-icon,
                    .p-menuitem-text {
                        color: inherit;
                    }

                    .p-icon-wrapper {
                        margin-top: 2px;

                        .p-icon {
                            margin-left: -1px;
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
            }
        }

        .p-submenu-list {
            width: 230px;

            .p-menuitem {
                .p-menuitem-link {
                    flex-direction: row;
                    min-height: 35px;
                    height: unset;

                    .p-menuitem-icon {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

// Main Menu - Compact
@media screen and (max-width: 960px) {

    .p-menubar {
        .p-icon {
            width: 16px;
            height: 16px;
        }

        .p-menuitem {

            .p-menuitem-link {
                min-height: 35px;

                .p-menuitem-icon {
                    font-size: 16px;
                    width: 18px;
                }

                .p-icon-wrapper {
                    .p-icon {
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }

        .p-submenu-list {

            .p-menuitem {
                .p-menuitem-link {
                    min-height: 33px;

                    .p-menuitem-icon {
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}