// ic-collapsible-div

$ic-cg-border-color: var(--border-grey);
$ic-cg-header-height: 34px;
$ic-cg-header-background-color: #fafafa;
$ic-cg-header-toggle-width: 34px;
$ic-cg-footer-height: 34px;
$ic-cg-footer-background-color: #fafafa;

#ic-cg-wrapper {
    border: 1px solid $ic-cg-border-color;
    width: 100%;
    position: relative;
    overflow: hidden;
    height: auto;
    transition: max-height 0.25s;

    #ic-cg-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: 0;
        height: $ic-cg-header-height;
        width: $ic-cg-header-toggle-width;
        background-color: $ic-cg-header-background-color;
        border-left: 1px solid $ic-cg-border-color;
        border-bottom: 1px solid $ic-cg-border-color;

        &:hover {
            cursor: pointer;
            background-color: darken($ic-cg-header-background-color, 5%);

            &:active {
                background-color: darken($ic-cg-header-background-color, 10%);
            }
        }
    }

    .widget-toolbar {
        position: absolute;
        right: 0;
        top: 0;
    }

    header {
        width: 100%;
        color: #333;
        border-bottom: 1px solid $ic-cg-border-color;
        background: $ic-cg-header-background-color;
        height: $ic-cg-header-height;
        padding: 0;

        h2 {
            line-height: 31px;
            height: 100%;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            padding: 0;
            margin: 0 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        h4 {
            line-height: 31px;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 0;
            padding: 0 10px;
            margin: 0;
        }
    }

    &.is-collapsible {
        header {
            width: calc(100% - $ic-cg-header-toggle-width);
        }
    }

    .widget-footer {
        height: $ic-cg-footer-height;
        background-color: $ic-cg-footer-background-color;
    }

    .widget-body {
        overflow: hidden;
        transition: all 0.5s;
        padding: 13px;
    }
}

#widgets-grid {
    #ic-cg-wrapper {
        margin-bottom: 10px;
    }
}

// ToolTip Component

.ic-tooltip-dialog {
    .mat-mdc-dialog-container {
        .mat-mdc-dialog-title {
            font-size: 22px;
            font-weight: bold;
            margin: 0 0 20px;
            letter-spacing: unset;
        }

        .mdc-dialog__content {
            letter-spacing: unset;
            line-height: unset;
            color: unset;

            h2 {
                letter-spacing: -1px;
                font-size: 20px;
                margin: 20px 0 2px 0;
                line-height: normal;
                font-weight: bold;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            .selection-explanation {
                margin: 8px 0 5px 10px;
            }
        }

        .mat-mdc-dialog-actions {
            margin-top: 10px;
        }
    }
}
